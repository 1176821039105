import React from 'react'
import IconList, { IconItem } from '../embeddable/IconList'

import * as styles from './index.module.scss'

import heart from './heart.png'

export default () => {
  return (<div className={styles.tippingContainer}>
    <h3>Dankeschön</h3>
    <p>So kannst du mich unterstützen</p>
    <IconList horizontal={true} center={true}>
      <IconItem to='/grammatikbuch/' icon='book'>Buch runterladen</IconItem>
      <IconItem to='/jakob/#trinkgeld' icon='coin'>Trinkgeld geben</IconItem>
      <IconItem to='/japanisch-buecher/' icon='amazon'>Amazon-Links benutzen</IconItem>
    </IconList>
    <img className={styles.heart} src={heart} width={16} height={16} alt='Herz'/>
  </div>)
}
