import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import Japanese from './Japanese'

interface IExample {
  de: string
  jp: string
}

interface IGrammar {
  grammar: string
  de: string
  example: IExample
  jlpt: string
  label: string
}

interface IGrammarTableProps {
  label: string
  list: string
  excludeLabel: string[]
}

export default (props: IGrammarTableProps) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(250);

  const pageChanged = (event: any) => {
    setPage(parseInt(event.target.getAttribute('data-page'), 10));
  }

  const { allGrammarsYaml } = useStaticQuery(graphql`query GrammarQuery {
    allGrammarsYaml {
      totalCount
      nodes {
        grammar
        de
        example {
          jp
          de
        }
        jlpt
        label
      }
    }
  }`);

  const grammars = allGrammarsYaml.nodes.filter((e: IGrammar) => 
    e.jlpt === props.list &&                                                              // by JLPT
    (!props.label || e.label === props.label) &&                                          // if label is set, filter by label
    (!props.excludeLabel || !props.excludeLabel.find((ex: string) => ex === e.label))  // label is not in exclude list
  );

  const items = [];
  const pageCount = Math.ceil(grammars.length / rowsPerPage);
  if (pageCount > 1) {
    for (let n = 0; n < pageCount; n++) {
      items.push(
        <Pagination.Item key={'page-'+n} active={n === page} onClick={pageChanged} data-page={n}>
          {n * rowsPerPage + 1} - {Math.min((n + 1) * rowsPerPage, grammars.length)}
        </Pagination.Item>,
      );
    }
  }

  return (<div>
    <table>
      <thead>
        <tr><th>Ausdruck</th><th>Beispiel</th></tr>
      </thead>
      <tbody>
      { 
        grammars.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((node: IGrammar, index: number ) => {
            const grammarNum = page * rowsPerPage + index + 1;
            return (<tr key={'grammar-' + grammarNum}>
              <td>{node.grammar}<br/>{node.de}</td>
              { node.example
                ? <td><Japanese text={node.example.jp}/><br/>{node.example.de}</td>
                : null
              }
            </tr>);
        })
      }
      </tbody>
    </table>
    <Pagination>{items}</Pagination>
  </div>);
}