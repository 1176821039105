import React, { useState } from 'react'
import * as wanakana from 'wanakana'

import { sentencesHiragana, sentencesKatakana } from './KanaTrainer-data'

import styles from './KanaTrainer.module.scss'

const sentenceSets = {
  'hiragana': sentencesHiragana,
  'katakana': sentencesKatakana
}

export default () => {
  const [sentence, setSentence] = useState<any>(undefined);
  const [activeWord, setActiveWord] = useState<number>(-1);
  const [input, setInput] = useState('');
  const [correct, setCorrect] = useState<boolean>(false);
  const [numMatching, setNumMatching] = useState<number>(0);
  const [trainerMode, setTrainerMode] = useState<'hiragana'|'katakana'>('hiragana');
  const [activeMeaning, setActiveMeaning] = useState<string>('');
  const [hint, setHint] = useState<string>('');

  let currentSentenceId: number;
  let history: number[] = [];

  const startHiragana = (event: any) => {
    event.preventDefault()
    nextQuestion('hiragana')
  }

  const startKatakana = (event: any) => {
    event.preventDefault()
    nextQuestion('katakana')
  }

  const restart = (event: any) => {
    event.preventDefault()
    setHint('');
    setSentence(undefined);
  }

  const onCheckAnswer = (event: any) => {
    event.preventDefault();
    checkAnswer(false, input);
  }

  const onInputChanged = (event: any) => {
    checkAnswer(true, event.target.value);
  }

  const onKeyUp = (event: any) => {
    event.preventDefault();
    setHint('');
    if (event.keyCode === 32 || event.keyCode === 13) {
      checkAnswer(false, input);
    }
  }

  const selectNewQuestion = (mode: 'hiragana'|'katakana') => {
    /* select new sentence, make sure it's different */
    do {
      currentSentenceId = Math.floor(Math.random() * sentenceSets[mode].length);
    } while (history.indexOf(currentSentenceId) !== -1);
    
    history.push(currentSentenceId);
    if (history.length > 30) {
      history.splice(0, 1);
    }
  
    const newSentence: { words: Array<{ kana: string, meaning?: string }> } = sentenceSets[mode][currentSentenceId];
    setSentence(newSentence);
    setActiveWord(0); // TODO set in single action?
    setTrainerMode(mode);
    setActiveMeaning(newSentence.words[0].meaning || '');
    setInput('');
    setCorrect(false);
    setNumMatching(0);
  }
  
  const nextQuestion = (mode: 'hiragana'|'katakana') => {
    // do {
      if (!sentence || activeWord >= sentence.words.length - 1) {
        selectNewQuestion(mode);
      }
      else {
        setActiveWord(activeWord + 1);
        // TODO doesn't work properly now
      }
    // } while (sentence && sentence.words[activeWord].meaning == undefined /*|| wanakana.toHiragana(currentSentence.words[currentWord].kana).endsWith('っ')*/);
  }

  const getMatchingLength = (str1: string, str2: string) => {
    let numMatch = 0;
    const answerArray = str1.split('');
    const questionArray = str2.split('');
    for (let i = 0; i < answerArray.length && i < questionArray.length; i++) {
      if (answerArray[i] !== questionArray[i]) {
        break;
      }
      numMatch ++;
    }
    return numMatch;
  }
  
  const toRomaji = (text: string) => {
    // Issue 1: small vocals are not handled properly
    const options = { customRomajiMapping: { っち: 'cchi', ー: '-', ふぁ: 'fa', ふぃ: 'fi', ふぇ: 'fe', ふぉ: 'fo', てぃ: 'thi', ぁ: 'xa', ぃ: 'xi', ぅ: 'xu', ぇ: 'xe', ぉ: 'xo' } };
    // Issue 2: wanakana is not converting ー to - between katakana.
    // Issue 3: っち is converting wrongly to tchi
    // Instead, I tokenize it and merge the converted tokens.
    const tokens = wanakana.tokenize(text);
    const converted = tokens.map(el => wanakana.toRomaji(el, options));
    return converted.join('');
  }

  const getAnswerResult = (answer: string, questionWord: string) => {
    /* number of matching kana */
    let hiragana = wanakana.toKatakana(answer);
    let numMatch = getMatchingLength(hiragana, wanakana.toKatakana(questionWord));
    let numMismatch = hiragana.length - numMatch;
  
    // automatically add ' if needed
    if (numMatch > 0 && numMismatch >= 1 && questionWord[numMatch] === 'ん') {
      for (let charsToRemove = 1; charsToRemove < 3; charsToRemove++) {
        const answerMinusChars = answer.substr(0, answer.length - charsToRemove);
        const numMatchWithMinusChars = getMatchingLength(wanakana.toKatakana(answerMinusChars), wanakana.toKatakana(questionWord));
  
        if (answerMinusChars.endsWith('n') && numMatchWithMinusChars > numMatch) {
          // add tick and re-evaluate
          answer = answerMinusChars + '\'' + answer.substr(answer.length - charsToRemove);
  
          hiragana = wanakana.toKatakana(answer);
          numMatch = getMatchingLength(hiragana, wanakana.toKatakana(questionWord));
          numMismatch = hiragana.length - numMatch;
          break;
        }
      }
    }
  
    const result = {
      correct: false,
      correctedInput: input,
      hint: '',
      nextRomaji: '',
      numMatch
    }
  
    result.correct = (numMatch === questionWord.length && numMatch === hiragana.length);
    if (result.correct) {
      // nothing
    }
    else if (numMatch >= questionWord.length) {
      result.hint = 'Du hast zu viel eingetippt: ' + toRomaji(questionWord);
    }
    else {
      // match small tsu and following small kana
      // also both in combination e.g. っしょ
      const smallKana = 'ゃゅょぁぃぅぇぉャュョァィゥェォ';
      let nextKanaToMatch = questionWord[numMatch];
      if (numMatch > 0 && -1 !== smallKana.indexOf(nextKanaToMatch)) {
        nextKanaToMatch = questionWord[numMatch - 1] + nextKanaToMatch;
      }
      if ((numMatch + 1 < questionWord.length) && (questionWord[numMatch] === 'っ' || questionWord[numMatch] === 'ッ')) {
        nextKanaToMatch += questionWord[numMatch + 1];
        numMatch ++;
      }
      if (numMatch + 1 < questionWord.length) {
        const followingKana = questionWord[numMatch + 1];
        if (-1 !== smallKana.indexOf(followingKana)) {
          nextKanaToMatch += followingKana;
        }
      }
  
      if (wanakana.toHiragana(nextKanaToMatch).startsWith('っ')) {
        result.nextRomaji = toRomaji(nextKanaToMatch);
        result.hint = 'Der Konsonant wird verlängert. ' + nextKanaToMatch + ' = ' + result.nextRomaji;
      }
      else {
        result.nextRomaji = toRomaji(nextKanaToMatch);
        result.hint = nextKanaToMatch + ' wird ' + result.nextRomaji + ' geschrieben.';
      }
    }
  
    return result;
  }

  const checkAnswer = (preview: boolean, answer: string) => {
    const trimmedInput = answer.trim();
    const questionWord = sentence.words[activeWord].kana;
    const result = getAnswerResult(answer, questionWord);
  
    setInput(result.correctedInput !== trimmedInput ? answer : result.correctedInput);
    setCorrect(result.correct);
    setNumMatching(result.numMatch);
    
    // check answer, if not preview
    if (!preview) {
      if (result.correct) {
        nextQuestion(trainerMode);
      }
      else {
        setHint(result.hint);
      }
    }
  }

  let kanaCounter = 0;

  return (<div id="kana-trainer" className={styles.kanaTrainer}>
  {!sentence && <div id="kana-prepare" className={styles.kanaPrepare}>
    <p>Welche Zeichen möchtest du lernen?</p>
    <p>
      <a href="#" role="button" onClick={startHiragana} className={styles.buttonPrimary}>Hiragana</a>
      <a href="#" role="button" onClick={startKatakana} className={styles.buttonPrimary}>Katakana</a>
    </p>
  </div>}
  {sentence && <div id="kana-training" className={styles.kanaTraining}>
    <div>
      <div id="word-sentence" />
      <div id="word-kana" className={styles.wordKana}> {
        sentence && sentence.words.map((word: {kana: string}, index: number) => (
          <span key={`word-${index}`} className={index === activeWord ? styles.kanaFocusActive : null}> {
            word.kana.split('').map((kana: string) => (
              <span key={`word-kana-single-${kanaCounter++}`} className={kanaCounter <= numMatching ? styles.kanaCorrect : null}>{kana}</span>
            ))
          } </span>
        ))
      } </div>
      <div className={correct?styles.meaning:styles.hiddenMeaning}>{activeMeaning}</div>
    </div>
    <div>
      <input value={input} onChange={onInputChanged} onKeyUp={onKeyUp}
             className={styles.inputRomaji} type="text" autoComplete={'off'} autoCorrect={'off'} autoCapitalize={'off'} spellCheck={'false'} />
    </div>
    <div className={styles.buttonNextArea}>
      {hint !== '' && <div className={styles.hintMessage}>{hint}</div>}
      <a href="#" onClick={onCheckAnswer} className={`${styles.kanaCheckBtn} ${correct?styles.kanaCheckBtn_correct:''}`}>
        {correct ? 'Richtig! Weiter' : 'Tipp zeigen!'}
      </a>
    </div>
    <p>Tippe den roten Text in Romaji ein - a für あ usw.<br/>[Eingabetaste] oder [Leertaste] für das nächste Wort.</p>
    <p>
      <a href="#" role="button" onClick={restart} className={styles.buttonSecondary}>Zurück</a>
    </p>
  </div>}
</div>);
};
