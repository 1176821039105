import React, { useState } from 'react'
import Booklinks, { IBookChapter } from './Booklinks'
import Tipping from './Tipping'

import styles from './SecondaryNavigation.module.scss'

interface ISidebarProps {
  booklinks: {
    title: string
    chapters: IBookChapter[]
  }
  location: any
}

const Sidebar = (props: ISidebarProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const navToggled = (event: any) => {
    event.preventDefault();
    setVisible(!visible);
  }

  if (props.booklinks) {
    return (<div className={styles.sidebar}>
      <div className={styles.navTitle}>
        <a href="#" onClick={navToggled}>Thema: {props.booklinks.title}</a>
        <i className={styles.arrow + (visible ? ` ${styles.arrowVisible}` : '')} />
      </div>
      <nav className={styles.navContent + (visible ? ` ${styles.navContentVisible}` : '')}>
        <div className={styles.navContentDiv}>
          <Booklinks chapters={props.booklinks.chapters} location={props.location}/>
        </div>
        <Tipping />
        <div className={styles.affiliateHint}>Wenn du etwas über einen Amazon-Link kaufst, bekomme ich eine Werbekostenerstattung. Das kostet dich nichts.</div>
      </nav>
    </div>);
  }

  return (<div />);
};

export default Sidebar;