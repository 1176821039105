import React, { ReactNode } from 'react'
import styles from './Centered.module.scss'

interface ICenteredProps {
  children: ReactNode,
}

export default (props: ICenteredProps) => {
  return <div className={styles.centered}>{props.children}</div>;
}
