import * as React from 'react'
import Layout from './Layout'

export default class BlogCommon extends React.Component<any, any> {
  public render() {
    return (
      <Layout booklinks={this.props.booklinks} captions={this.props.captions} location={this.props.location}>
        <div>
          <h1>{this.props.title}</h1>
          {
            this.props.children
          }
          <p style={{textAlign: `right`, marginTop: `3rem` }}>Zuletzt aktualisiert: {this.props.date}</p>
        </div>
      </Layout>
    );
  }
}