export const sentencesHiragana = [
  {"words": [{"kana": "あ", meaning: "Ah"}]},
  {"words": [{"kana": "あう", meaning: "sich versammeln, treffen"}]},
  {"words": [{"kana": "あお", meaning: "Farbe Blau"}]},
  {"words": [{"kana": "あおい", meaning: "blau"}]},
  {"words": [{"kana": "あか", meaning: "Farbe Rot"}]},
  {"words": [{"kana": "あかい", meaning: "rot"}]},
  {"words": [{"kana": "あがる", meaning: "hochsteigen"}]},
  {"words": [{"kana": "あかるい", meaning: "hell"}]},
  {"words": [{"kana": "あき", meaning: "Herbst"}]},
  {"words": [{"kana": "あく", meaning: "sich öffnen"}]},
  {"words": [{"kana": "あげる", meaning: "hochheben, geben"}]},
  {"words": [{"kana": "あける", meaning: "öffnen"}]},
  {"words": [{"kana": "あさ", meaning: "Morgen"}]},
  {"words": [{"kana": "あさごはん", meaning: "Frühstück"}]},
  {"words": [{"kana": "あさって", meaning: "übermorgen"}]},
  {"words": [{"kana": "あし", meaning: "Bein"}]},
  {"words": [{"kana": "あした", meaning: "morgen"}]},
  {"words": [{"kana": "あそこ", meaning: "dort (entfernt)"}]},
  {"words": [{"kana": "あそぶ", meaning: "spielen"}]},
  {"words": [{"kana": "あたたかい", meaning: "warm"}]},
  {"words": [{"kana": "あたま", meaning: "Kopf"}]},
  {"words": [{"kana": "あたらしい", meaning: "neu"}]},
  {"words": [{"kana": "あちら", meaning: "dort (hin) (entfernt)"}]},
  {"words": [{"kana": "あつい", meaning: "heiß, dick"}]},
  {"words": [{"kana": "あっち", meaning: "dort (hin) (entfernt)"}]},
  {"words": [{"kana": "あと", meaning: "hinter, später"}]},
  {"words": [{"kana": "あなた", meaning: "Du, Sie"}]},
  {"words": [{"kana": "あに", meaning: "(mein) älterer Bruder"}]},
  {"words": [{"kana": "あね", meaning: "(meine) ältere Schwester"}]},
  {"words": [{"kana": "あの", meaning: "dieses dort, ehm"}]},
  {"words": [{"kana": "あぶない", meaning: "gefährlich"}]},
  {"words": [{"kana": "あまい", meaning: "süß (Essen)"}]},
  {"words": [{"kana": "あまり", meaning: "weniger"}]},
  {"words": [{"kana": "あめ", meaning: "Bonbon"}]},
  {"words": [{"kana": "あめ", meaning: "Regen"}]},
  {"words": [{"kana": "あらう", meaning: "waschen"}]},
  {"words": [{"kana": "ありがとう", meaning: "Danke"}]},
  {"words": [{"kana": "ありがとうございます", meaning: "Dankeschön (höfl.)"}]},
  {"words": [{"kana": "ある", meaning: "haben, geben, existieren"}]},
  {"words": [{"kana": "あるく", meaning: "zu Fuß gehen"}]},
  {"words": [{"kana": "あれ", meaning: "das dort"}]},
  {"words": [{"kana": "いい", meaning: "gut"}]},
  {"words": [{"kana": "いいえ", meaning: "Nein"}]},
  {"words": [{"kana": "いう", meaning: "sagen"}]},
  {"words": [{"kana": "いえ", meaning: "Haus"}]},
  {"words": [{"kana": "いかが", meaning: "wie"}]},
  {"words": [{"kana": "いく", meaning: "gehen"}]},
  {"words": [{"kana": "いくつ", meaning: "wie viel, einige"}]},
  {"words": [{"kana": "いくら", meaning: "wie viel, einige"}]},
  {"words": [{"kana": "いけ", meaning: "Teich"}]},
  {"words": [{"kana": "いしゃ", meaning: "Arzt"}]},
  {"words": [{"kana": "いす", meaning: "Stuhl"}]},
  {"words": [{"kana": "いそがしい", meaning: "beschäftigt"}]},
  {"words": [{"kana": "いたい", meaning: "schmerzen"}]},
  {"words": [{"kana": "いただきます", meaning: "Danke für das Essen. (vorher)"}]},
  {"words": [{"kana": "いち", meaning: "eins"}]},
  {"words": [{"kana": "いちがつ", meaning: "Januar"}]},
  {"words": [{"kana": "いちじ", meaning: "1 Uhr"}]},
  {"words": [{"kana": "いちにち", meaning: "1 Tag, den ganzen Tag"}]},
  {"words": [{"kana": "いちばん", meaning: "Erster"}]},
  {"words": [{"kana": "いつ", meaning: "wann"}]},
  {"words": [{"kana": "いつか", meaning: "5. Tag eines Monats"}]},
  {"words": [{"kana": "いつかかん", meaning: "5 Tage"}]},
  {"words": [{"kana": "いっしょ", meaning: "zusammen, gleich"}]},
  {"words": [{"kana": "いつつ", meaning: "fünf (Stück)"}]},
  {"words": [{"kana": "いってきます", meaning: "Verabschiedung beim Zuhause Losgehen"}]},
  {"words": [{"kana": "いっぴき", meaning: "1 Tier"}]},
  {"words": [{"kana": "いっぷん", meaning: "1 Minute"}]},
  {"words": [{"kana": "いっぽん", meaning: "1 (Flasche, ..)"}]},
  {"words": [{"kana": "いつも", meaning: "immer"}]},
  {"words": [{"kana": "いぬ", meaning: "Hund"}]},
  {"words": [{"kana": "いま", meaning: "jetzt"}]},
  {"words": [{"kana": "いみ", meaning: "Bedeutung"}]},
  {"words": [{"kana": "いもうと", meaning: "jüngere Schwester"}]},
  {"words": [{"kana": "いや", meaning: "unangenehm"}]},
  {"words": [{"kana": "いらっしゃいませ", meaning: "Willkommen. (im Geschäft)"}]},
  {"words": [{"kana": "いりぐち", meaning: "Eingang"}]},
  {"words": [{"kana": "いる", meaning: "sein"}]},
  {"words": [{"kana": "いれる", meaning: "hineintun"}]},
  {"words": [{"kana": "いろ", meaning: "Farbe"}]},
  {"words": [{"kana": "いろいろ", meaning: "unterschiedliches"}]},
  {"words": [{"kana": "うえ", meaning: "oben"}]},
  {"words": [{"kana": "うしろ", meaning: "Rückseite"}]},
  {"words": [{"kana": "うすい", meaning: "dünn"}]},
  {"words": [{"kana": "うた", meaning: "Lied"}]},
  {"words": [{"kana": "うたう", meaning: "singen"}]},
  {"words": [{"kana": "うまれる", meaning: "geboren werden"}]},
  {"words": [{"kana": "うみ", meaning: "Meer"}]},
  {"words": [{"kana": "うる", meaning: "verkaufen"}]},
  {"words": [{"kana": "うるさい", meaning: "laut"}]},
  {"words": [{"kana": "うれしい", meaning: "sich freuen"}]},
  {"words": [{"kana": "うわぎ", meaning: "Sakko, Jacke"}]},
  {"words": [{"kana": "え", meaning: "Bild"}]},
  {"words": [{"kana": "えいが", meaning: "Film"}]},
  {"words": [{"kana": "えいがかん", meaning: "Kino"}]},
  {"words": [{"kana": "えいご", meaning: "Englisch"}]},
  {"words": [{"kana": "ええ", meaning: "ja"}]},
  {"words": [{"kana": "えき", meaning: "Bahnhof"}]},
  {"words": [{"kana": "えらぶ", meaning: "auswählen"}]},
  {"words": [{"kana": "えんぴつ", meaning: "Bleistift"}]},
  {"words": [{"kana": "おいしい", meaning: "lecker"}]},
  {"words": [{"kana": "おおい", meaning: "viel"}]},
  {"words": [{"kana": "おおきい", meaning: "groß"}]},
  {"words": [{"kana": "おおきな", meaning: "groß"}]},
  {"words": [{"kana": "おおぜい", meaning: "große Anzahl von Menschen"}]},
  {"words": [{"kana": "おかあさん", meaning: "Mutter (anderer Leute)"}]},
  {"words": [{"kana": "おかし", meaning: "Süßigkeiten"}]},
  {"words": [{"kana": "おかね", meaning: "Geld"}]},
  {"words": [{"kana": "おきる", meaning: "aufstehen"}]},
  {"words": [{"kana": "おく", meaning: "hinstellen, hinlegen"}]},
  {"words": [{"kana": "おくさん", meaning: "jmds. anderen Ehefrau"}]},
  {"words": [{"kana": "おくに", meaning: "Heimatland"}]},
  {"words": [{"kana": "おさけ", meaning: "Sake"}]},
  {"words": [{"kana": "おさら", meaning: "Teller"}]},
  {"words": [{"kana": "おじいさん", meaning: "Großvater"}]},
  {"words": [{"kana": "おしえる", meaning: "lehren"}]},
  {"words": [{"kana": "おじさん", meaning: "Onkel"}]},
  {"words": [{"kana": "おす", meaning: "drücken"}]},
  {"words": [{"kana": "おそい", meaning: "spät"}]},
  {"words": [{"kana": "おちゃ", meaning: "Tee"}]},
  {"words": [{"kana": "おてあらい", meaning: "Toilette"}]},
  {"words": [{"kana": "おとうさん", meaning: "Vater (anderer Leute)"}]},
  {"words": [{"kana": "おとうと", meaning: "jüngerer Bruder"}]},
  {"words": [{"kana": "おとこ", meaning: "Mann"}]},
  {"words": [{"kana": "おとこのこ", meaning: "Junge"}]},
  {"words": [{"kana": "おとこのひと", meaning: "Mann"}]},
  {"words": [{"kana": "おととい", meaning: "vorgestern"}]},
  {"words": [{"kana": "おととし", meaning: "vorletztes Jahr"}]},
  {"words": [{"kana": "おとな", meaning: "Erwachsener"}]},
  {"words": [{"kana": "おなか", meaning: "Bauch"}]},
  {"words": [{"kana": "おなじ", meaning: "gleich"}]},
  {"words": [{"kana": "おにいさん", meaning: "älterer Bruder"}]},
  {"words": [{"kana": "おにぎり", meaning: "Onigiri, Reisball"}]},
  {"words": [{"kana": "おねえさん", meaning: "ältere Schwester"}]},
  {"words": [{"kana": "おねがいします", meaning: "Ich bitte Sie, dich."}]},
  {"words": [{"kana": "おばあさん", meaning: "Großmutter"}]},
  {"words": [{"kana": "おばさん", meaning: "Tante, Frau mittleren Alters"}]},
  {"words": [{"kana": "おふろ", meaning: "Bad"}]},
  {"words": [{"kana": "おべんとう", meaning: "Bentō"}]},
  {"words": [{"kana": "おぼえる", meaning: "lernen"}]},
  {"words": [{"kana": "おまわりさん", meaning: "Polizist"}]},
  {"words": [{"kana": "おもい", meaning: "schwer"}]},
  {"words": [{"kana": "おもしろい", meaning: "lustig, interessant"}]},
  {"words": [{"kana": "およぐ", meaning: "schwimmen"}]},
  {"words": [{"kana": "おりる", meaning: "herabsteigen"}]},
  {"words": [{"kana": "おわる", meaning: "aufhören"}]},
  {"words": [{"kana": "おんがく", meaning: "Musik"}]},
  {"words": [{"kana": "おんな", meaning: "Frau (urspr. junge Frau)"}]},
  {"words": [{"kana": "おんなのこ", meaning: "Mädchen"}]},
  {"words": [{"kana": "おんなのひと", meaning: "Frau"}]},
  {"words": [{"kana": "か", meaning: "Dienstag (Abkürzung)"}]},
  {"words": [{"kana": "がいこく", meaning: "Ausland"}]},
  {"words": [{"kana": "がいこくじん", meaning: "Ausländer"}]},
  {"words": [{"kana": "かいしゃ", meaning: "Firma"}]},
  {"words": [{"kana": "かいだん", meaning: "Treppe"}]},
  {"words": [{"kana": "かいもの", meaning: "Einkauf"}]},
  {"words": [{"kana": "かう", meaning: "kaufen"}]},
  {"words": [{"kana": "かえす", meaning: "zurückgeben"}]},
  {"words": [{"kana": "かえる", meaning: "zurückgehen"}]},
  {"words": [{"kana": "かかる", meaning: "kosten (Zeit, Geld)"}]},
  {"words": [{"kana": "かぎ", meaning: "Schlüssel"}]},
  {"words": [{"kana": "かく", meaning: "schreiben"}]},
  {"words": [{"kana": "がくせい", meaning: "Student"}]},
  {"words": [{"kana": "かける", meaning: "aufwenden (Zeit, Geld), aufhängen"}]},
  {"words": [{"kana": "かさ", meaning: "Schirm"}]},
  {"words": [{"kana": "かす", meaning: "verleihen"}]},
  {"words": [{"kana": "かぜ", meaning: "Wind, Erkältung"}]},
  {"words": [{"kana": "かぞく", meaning: "Familie"}]},
  {"words": [{"kana": "かた", meaning: "Person, Art und Weise"}]},
  {"words": [{"kana": "がっこう", meaning: "Schule"}]},
  {"words": [{"kana": "かてい", meaning: "Familie"}]},
  {"words": [{"kana": "かど", meaning: "Ecke"}]},
  {"words": [{"kana": "かばん", meaning: "Koffer"}]},
  {"words": [{"kana": "かびん", meaning: "Vase"}]},
  {"words": [{"kana": "かみ", meaning: "Papier"}]},
  {"words": [{"kana": "かようび", meaning: "Dienstag"}]},
  {"words": [{"kana": "からい", meaning: "scharf"}]},
  {"words": [{"kana": "からだ", meaning: "Körper"}]},
  {"words": [{"kana": "かりる", meaning: "ausleihen"}]},
  {"words": [{"kana": "かるい", meaning: "leicht"}]},
  {"words": [{"kana": "かわ", meaning: "Fluss"}]},
  {"words": [{"kana": "かわいい", meaning: "niedlich"}]},
  {"words": [{"kana": "かんがえる", meaning: "nachdenken"}]},
  {"words": [{"kana": "かんじ", meaning: "Kanji"}]},
  {"words": [{"kana": "かんたん", meaning: "leicht"}]},
  {"words": [{"kana": "き", meaning: "Baum"}]},
  {"words": [{"kana": "きいろ", meaning: "Gelb"}]},
  {"words": [{"kana": "きいろい", meaning: "gelb"}]},
  {"words": [{"kana": "きえる", meaning: "verschwinden, ausgehen"}]},
  {"words": [{"kana": "きく", meaning: "hören"}]},
  {"words": [{"kana": "きた", meaning: "Norden"}]},
  {"words": [{"kana": "きたない", meaning: "schmutzig"}]},
  {"words": [{"kana": "きっさてん", meaning: "Teehaus"}]},
  {"words": [{"kana": "きって", meaning: "Briefmarke"}]},
  {"words": [{"kana": "きっぷ", meaning: "Ticket"}]},
  {"words": [{"kana": "きのう", meaning: "gestern"}]},
  {"words": [{"kana": "きゅう", meaning: "neun"}]},
  {"words": [{"kana": "ぎゅうにく", meaning: "Rindfleisch"}]},
  {"words": [{"kana": "ぎゅうにゅう", meaning: "Milch"}]},
  {"words": [{"kana": "きゅうひき", meaning: "9 Tiere"}]},
  {"words": [{"kana": "きゅうふん", meaning: "9 Minuten"}]},
  {"words": [{"kana": "きゅうほん", meaning: "9 (Flaschen, ..)"}]},
  {"words": [{"kana": "きょう", meaning: "heute"}]},
  {"words": [{"kana": "きょうしつ", meaning: "Klassenzimmer"}]},
  {"words": [{"kana": "きょうだい", meaning: "Brüder"}]},
  {"words": [{"kana": "きょうと", meaning: "Kioto"}]},
  {"words": [{"kana": "きょねん", meaning: "letztes Jahr"}]},
  {"words": [{"kana": "きらい", meaning: "nicht gemocht sein, gehasst sein"}]},
  {"words": [{"kana": "きる", meaning: "anziehen, schneiden"}]},
  {"words": [{"kana": "きん", meaning: "Freitag (Abkürzung)"}]},
  {"words": [{"kana": "ぎんこう", meaning: "Bank"}]},
  {"words": [{"kana": "きんようび", meaning: "Freitag"}]},
  {"words": [{"kana": "く", meaning: "neun"}]},
  {"words": [{"kana": "くがつ", meaning: "September"}]},
  {"words": [{"kana": "くじ", meaning: "9 Uhr"}]},
  {"words": [{"kana": "くすり", meaning: "Arznei"}]},
  {"words": [{"kana": "ください", meaning: "bitte"}]},
  {"words": [{"kana": "くだもの", meaning: "Obst"}]},
  {"words": [{"kana": "くち", meaning: "Mund"}]},
  {"words": [{"kana": "くつ", meaning: "Schuh"}]},
  {"words": [{"kana": "くつした", meaning: "Socken"}]},
  {"words": [{"kana": "くに", meaning: "Land"}]},
  {"words": [{"kana": "くもり", meaning: "Bewölkung"}]},
  {"words": [{"kana": "くもる", meaning: "sich bewölken"}]},
  {"words": [{"kana": "くらい", meaning: "dunkel"}]},
  {"words": [{"kana": "くる", meaning: "kommen"}]},
  {"words": [{"kana": "くるま", meaning: "Auto"}]},
  {"words": [{"kana": "くろ", meaning: "Schwarz"}]},
  {"words": [{"kana": "くろい", meaning: "schwarz"}]},
  {"words": [{"kana": "けいかん", meaning: "Polizeibeamter"}]},
  {"words": [{"kana": "けさ", meaning: "heute Morgen"}]},
  {"words": [{"kana": "けしごむ", meaning: "Radiergummi"}]},
  {"words": [{"kana": "けす", meaning: "löschen"}]},
  {"words": [{"kana": "げつ", meaning: "Montag (Abkürzung)"}]},
  {"words": [{"kana": "けっこう", meaning: "ziemlich"}]},
  {"words": [{"kana": "けっこん", meaning: "Heirat"}]},
  {"words": [{"kana": "げつようび", meaning: "Montag"}]},
  {"words": [{"kana": "げんかん", meaning: "Eingang"}]},
  {"words": [{"kana": "げんき", meaning: "Munter, Wohlfühlen"}]},
  {"words": [{"kana": "げんご", meaning: "Sprache"}]},
  {"words": [{"kana": "ご", meaning: "fünf"}]},
  {"words": [{"kana": "ごい", meaning: "Wortschatz"}]},
  {"words": [{"kana": "こうえん", meaning: "öffentlicher Park"}]},
  {"words": [{"kana": "こうさてん", meaning: "Kreuzung"}]},
  {"words": [{"kana": "こうちゃ", meaning: "schwarzer Tee"}]},
  {"words": [{"kana": "こうばん", meaning: "Polizeiwache"}]},
  {"words": [{"kana": "こえ", meaning: "Stimme"}]},
  {"words": [{"kana": "ごがつ", meaning: "Mai"}]},
  {"words": [{"kana": "ここ", meaning: "hier"}]},
  {"words": [{"kana": "ごご", meaning: "Nachmittag"}]},
  {"words": [{"kana": "ここのか", meaning: "9. Tag eines Monats"}]},
  {"words": [{"kana": "ここのかかん", meaning: "9 Tage"}]},
  {"words": [{"kana": "ここのつ", meaning: "neun (Stück)"}]},
  {"words": [{"kana": "ごじ", meaning: "5 Uhr"}]},
  {"words": [{"kana": "ごぜん", meaning: "Vormittag"}]},
  {"words": [{"kana": "こたえる", meaning: "antworten"}]},
  {"words": [{"kana": "ごちそうさまでした", meaning: "Danke für das Essen. (hinterher)"}]},
  {"words": [{"kana": "こちら", meaning: "hier (her)"}]},
  {"words": [{"kana": "こっち", meaning: "hier (her)"}]},
  {"words": [{"kana": "こと", meaning: "Ding, Sache"}]},
  {"words": [{"kana": "ことし", meaning: "dieses Jahr"}]},
  {"words": [{"kana": "ことば", meaning: "das Wort"}]},
  {"words": [{"kana": "こども", meaning: "Kind"}]},
  {"words": [{"kana": "この", meaning: "dieses (hier)"}]},
  {"words": [{"kana": "ごはん", meaning: "Essen, Reis"}]},
  {"words": [{"kana": "ごひき", meaning: "5 Tiere"}]},
  {"words": [{"kana": "ごふん", meaning: "5 Minuten"}]},
  {"words": [{"kana": "ごほん", meaning: "5 (Flaschen, ..)"}]},
  {"words": [{"kana": "こまる", meaning: "in Schwierigkeiten sein"}]},
  {"words": [{"kana": "これ", meaning: "das hier"}]},
  {"words": [{"kana": "こんげつ", meaning: "dieser Monat"}]},
  {"words": [{"kana": "こんしゅう", meaning: "diese Woche"}]},
  {"words": [{"kana": "こんな", meaning: "so ein"}]},
  {"words": [{"kana": "こんばん", meaning: "heute Abend"}]},
  {"words": [{"kana": "さあ", meaning: "Nun ... (Satzanfang)"}]},
  {"words": [{"kana": "さいご", meaning: "letzte"}]},
  {"words": [{"kana": "さいふ", meaning: "Portemonnaie"}]},
  {"words": [{"kana": "さかな", meaning: "Fisch"}]},
  {"words": [{"kana": "さき", meaning: "vorher, Zukunft, Spitze"}]},
  {"words": [{"kana": "さく", meaning: "blühen"}]},
  {"words": [{"kana": "さくぶん", meaning: "Aufsatz"}]},
  {"words": [{"kana": "さす", meaning: "zeigen, (Schirm) hochhalten"}]},
  {"words": [{"kana": "ざっし", meaning: "Zeitschrift"}]},
  {"words": [{"kana": "さとう", meaning: "Zucker"}]},
  {"words": [{"kana": "さむい", meaning: "kalt"}]},
  {"words": [{"kana": "さようなら", meaning: "Auf Wiedersehen"}]},
  {"words": [{"kana": "さらいねん", meaning: "übernächstes Jahr"}]},
  {"words": [{"kana": "さん", meaning: "drei"}]},
  {"words": [{"kana": "さんがつ", meaning: "März"}]},
  {"words": [{"kana": "さんじ", meaning: "3 Uhr"}]},
  {"words": [{"kana": "さんにん", meaning: "3 Personen"}]},
  {"words": [{"kana": "さんびき", meaning: "3 Tiere"}]},
  {"words": [{"kana": "さんぷん", meaning: "3 Minuten"}]},
  {"words": [{"kana": "さんぽする", meaning: "spazieren gehen"}]},
  {"words": [{"kana": "さんぼん", meaning: "3 (Flaschen, ..)"}]},
  {"words": [{"kana": "し", meaning: "vier"}]},
  {"words": [{"kana": "しお", meaning: "Salz"}]},
  {"words": [{"kana": "しかし", meaning: "aber"}]},
  {"words": [{"kana": "しがつ", meaning: "April"}]},
  {"words": [{"kana": "じかん", meaning: "Zeit"}]},
  {"words": [{"kana": "しけん", meaning: "Test"}]},
  {"words": [{"kana": "しごと", meaning: "Arbeit"}]},
  {"words": [{"kana": "じしょ", meaning: "Wörterbuch"}]},
  {"words": [{"kana": "しずか", meaning: "still"}]},
  {"words": [{"kana": "した", meaning: "unten"}]},
  {"words": [{"kana": "しち", meaning: "sieben"}]},
  {"words": [{"kana": "しちがつ", meaning: "Juli"}]},
  {"words": [{"kana": "しちじ", meaning: "7 Uhr"}]},
  {"words": [{"kana": "しつもん", meaning: "Frage"}]},
  {"words": [{"kana": "しつれいしました", meaning: "Entschuldigung. (hinterher)"}]},
  {"words": [{"kana": "じてんしゃ", meaning: "Fahrrad"}]},
  {"words": [{"kana": "じどうしゃ", meaning: "Auto"}]},
  {"words": [{"kana": "しぬ", meaning: "sterben"}]},
  {"words": [{"kana": "じびき", meaning: "Kanjilexikon"}]},
  {"words": [{"kana": "じぶん", meaning: "selbst"}]},
  {"words": [{"kana": "しまる", meaning: "sich schließen"}]},
  {"words": [{"kana": "しめる", meaning: "zubinden, schließen"}]},
  {"words": [{"kana": "じゃ", meaning: "also dann"}]},
  {"words": [{"kana": "じゃ、また", meaning: "Verabschiedung"}]},
  {"words": [{"kana": "じゃあ", meaning: "also dann"}]},
  {"words": [{"kana": "しゃしん", meaning: "Fotografie"}]},
  {"words": [{"kana": "じゅう", meaning: "10"}]},
  {"words": [{"kana": "じゅういちがつ", meaning: "November"}]},
  {"words": [{"kana": "じゅういちじ", meaning: "11 Uhr"}]},
  {"words": [{"kana": "じゅういちにち", meaning: "11. Tag eines Monats"}]},
  {"words": [{"kana": "じゅうがつ", meaning: "Oktober"}]},
  {"words": [{"kana": "じゅうじ", meaning: "10 Uhr"}]},
  {"words": [{"kana": "じゅうにがつ", meaning: "Dezember"}]},
  {"words": [{"kana": "じゅうにじ", meaning: "12 Uhr"}]},
  {"words": [{"kana": "じゅうににち", meaning: "12. Tag eines Monats"}]},
  {"words": [{"kana": "じゅぎょう", meaning: "Unterricht"}]},
  {"words": [{"kana": "しゅくだい", meaning: "Hausaufgaben"}]},
  {"words": [{"kana": "じゅっぴき", meaning: "10 Tiere"}]},
  {"words": [{"kana": "じゅっぷん", meaning: "10 Minuten"}]},
  {"words": [{"kana": "じゅっぽん", meaning: "10 (Flaschen, ..)"}]},
  {"words": [{"kana": "じょうず", meaning: "geschickt"}]},
  {"words": [{"kana": "じょうぶ", meaning: "robust"}]},
  {"words": [{"kana": "しょうゆ", meaning: "Shoyu-Sauce"}]},
  {"words": [{"kana": "しょくどう", meaning: "Speisezimmer"}]},
  {"words": [{"kana": "しる", meaning: "wissen"}]},
  {"words": [{"kana": "しろ", meaning: "Weiß"}]},
  {"words": [{"kana": "しろい", meaning: "weiß"}]},
  {"words": [{"kana": "しんぶん", meaning: "Zeitung"}]},
  {"words": [{"kana": "すい", meaning: "Mittwoch (Abkürzung)"}]},
  {"words": [{"kana": "すいようび", meaning: "Mittwoch"}]},
  {"words": [{"kana": "すう", meaning: "einatmen"}]},
  {"words": [{"kana": "すき", meaning: "mögen, lieben"}]},
  {"words": [{"kana": "すくない", meaning: "wenig"}]},
  {"words": [{"kana": "すぐに", meaning: "sofort"}]},
  {"words": [{"kana": "すこし", meaning: "etwas"}]},
  {"words": [{"kana": "すし", meaning: "Sushi"}]},
  {"words": [{"kana": "すしや", meaning: "Sushibar"}]},
  {"words": [{"kana": "すずしい", meaning: "kühl"}]},
  {"words": [{"kana": "すみません", meaning: "Entschuldigung"}]},
  {"words": [{"kana": "すむ", meaning: "wohnen"}]},
  {"words": [{"kana": "する", meaning: "machen"}]},
  {"words": [{"kana": "すわる", meaning: "sitzen"}]},
  {"words": [{"kana": "せ", meaning: "Rücken"}]},
  {"words": [{"kana": "せいと", meaning: "Schüler"}]},
  {"words": [{"kana": "せっけん", meaning: "Seife"}]},
  {"words": [{"kana": "せまい", meaning: "eng"}]},
  {"words": [{"kana": "せん", meaning: "tausend"}]},
  {"words": [{"kana": "せんげつ", meaning: "voriger Monat"}]},
  {"words": [{"kana": "せんしゅう", meaning: "letzte Woche"}]},
  {"words": [{"kana": "せんせい", meaning: "Lehrer"}]},
  {"words": [{"kana": "せんたく", meaning: "Wäsche"}]},
  {"words": [{"kana": "ぜんぶ", meaning: "alle"}]},
  {"words": [{"kana": "そうじ", meaning: "Saubermachen"}]},
  {"words": [{"kana": "そうじする", meaning: "sauber machen"}]},
  {"words": [{"kana": "そこ", meaning: "dort"}]},
  {"words": [{"kana": "そして", meaning: "danach"}]},
  {"words": [{"kana": "そちら", meaning: "dort (hin)"}]},
  {"words": [{"kana": "そっち", meaning: "dort (hin)"}]},
  {"words": [{"kana": "そと", meaning: "außen"}]},
  {"words": [{"kana": "その", meaning: "dieses dort"}]},
  {"words": [{"kana": "そば", meaning: "daneben"}]},
  {"words": [{"kana": "そら", meaning: "Himmel"}]},
  {"words": [{"kana": "それ", meaning: "das dort"}]},
  {"words": [{"kana": "それから", meaning: "danach"}]},
  {"words": [{"kana": "それでは", meaning: "dann (auch als Verabschiedung)"}]},
  {"words": [{"kana": "だいがく", meaning: "Universität"}]},
  {"words": [{"kana": "たいしかん", meaning: "Botschaft"}]},
  {"words": [{"kana": "だいじょうぶ", meaning: "alles in Ordnung"}]},
  {"words": [{"kana": "だいすき", meaning: "sehr mögen, lieben"}]},
  {"words": [{"kana": "たいせつ", meaning: "wichtig"}]},
  {"words": [{"kana": "だいたい", meaning: "größtenteils"}]},
  {"words": [{"kana": "だいどころ", meaning: "Küche"}]},
  {"words": [{"kana": "たいへん", meaning: "sehr, schwierig"}]},
  {"words": [{"kana": "たかい", meaning: "hoch"}]},
  {"words": [{"kana": "だから", meaning: "deswegen"}]},
  {"words": [{"kana": "たくさん", meaning: "viel"}]},
  {"words": [{"kana": "だす", meaning: "herausholen"}]},
  {"words": [{"kana": "ただいま", meaning: "Begrüßung beim Zuhause Ankommen"}]},
  {"words": [{"kana": "ただしい", meaning: "richtig"}]},
  {"words": [{"kana": "たつ", meaning: "aufstehen"}]},
  {"words": [{"kana": "たてもの", meaning: "Gebäude"}]},
  {"words": [{"kana": "たのしい", meaning: "angenehm"}]},
  {"words": [{"kana": "たのむ", meaning: "bitten"}]},
  {"words": [{"kana": "たばこ", meaning: "Zigaretten"}]},
  {"words": [{"kana": "たぶん", meaning: "vielleicht"}]},
  {"words": [{"kana": "たべもの", meaning: "Essen"}]},
  {"words": [{"kana": "たべる", meaning: "essen"}]},
  {"words": [{"kana": "たまご", meaning: "Ei"}]},
  {"words": [{"kana": "だれ", meaning: "wer"}]},
  {"words": [{"kana": "だれか", meaning: "jemand"}]},
  {"words": [{"kana": "たんじょうび", meaning: "Geburtstag"}]},
  {"words": [{"kana": "だんだん", meaning: "nach und nach"}]},
  {"words": [{"kana": "ちいさい", meaning: "klein"}]},
  {"words": [{"kana": "ちいさな", meaning: "klein"}]},
  {"words": [{"kana": "ちかい", meaning: "nahe"}]},
  {"words": [{"kana": "ちがう", meaning: "anders sein"}]},
  {"words": [{"kana": "ちかく", meaning: "in der Nähe"}]},
  {"words": [{"kana": "ちかてつ", meaning: "U-Bahn"}]},
  {"words": [{"kana": "ちず", meaning: "Karte"}]},
  {"words": [{"kana": "ちち", meaning: "(mein) Vater"}]},
  {"words": [{"kana": "ちゃいろ", meaning: "Braun"}]},
  {"words": [{"kana": "ちゃわん", meaning: "Schüssel"}]},
  {"words": [{"kana": "ちょうど", meaning: "genau"}]},
  {"words": [{"kana": "ちょっと", meaning: "etwas"}]},
  {"words": [{"kana": "ついたち", meaning: "1. Tag eines Monats"}]},
  {"words": [{"kana": "つかう", meaning: "benutzen"}]},
  {"words": [{"kana": "つかれる", meaning: "müde werden"}]},
  {"words": [{"kana": "つぎ", meaning: "der Nächste"}]},
  {"words": [{"kana": "つく", meaning: "ankommen"}]},
  {"words": [{"kana": "つくえ", meaning: "Schreibtisch"}]},
  {"words": [{"kana": "つくりかた", meaning: "wie es hergestellt wird"}]},
  {"words": [{"kana": "つくる", meaning: "herstellen"}]},
  {"words": [{"kana": "つける", meaning: "einschalten, anbringen"}]},
  {"words": [{"kana": "つとめる", meaning: "arbeiten"}]},
  {"words": [{"kana": "つまらない", meaning: "langweilig"}]},
  {"words": [{"kana": "つめたい", meaning: "kalt"}]},
  {"words": [{"kana": "つよい", meaning: "stark"}]},
  {"words": [{"kana": "て", meaning: "Hand"}]},
  {"words": [{"kana": "でかける", meaning: "ausgehen"}]},
  {"words": [{"kana": "てがみ", meaning: "Brief"}]},
  {"words": [{"kana": "できる", meaning: "können"}]},
  {"words": [{"kana": "でぐち", meaning: "Ausgang"}]},
  {"words": [{"kana": "では", meaning: "dann (auch als Verabschiedung)"}]},
  {"words": [{"kana": "でも", meaning: "aber"}]},
  {"words": [{"kana": "でる", meaning: "hinausgehen"}]},
  {"words": [{"kana": "てんき", meaning: "Wetter"}]},
  {"words": [{"kana": "でんき", meaning: "elektrisches Licht"}]},
  {"words": [{"kana": "でんしゃ", meaning: "(elektrische) Bahn"}]},
  {"words": [{"kana": "でんわ", meaning: "Telefon"}]},
  {"words": [{"kana": "でんわばんごう", meaning: "Telefonnummer"}]},
  {"words": [{"kana": "ど", meaning: "Samstag (Abkürzung)"}]},
  {"words": [{"kana": "どいつご", meaning: "Deutsch (Sprache)"}]},
  {"words": [{"kana": "どう", meaning: "wie"}]},
  {"words": [{"kana": "どういたしまして", meaning: "Gern geschehen."}]},
  {"words": [{"kana": "とうきょう", meaning: "Tokio"}]},
  {"words": [{"kana": "どうして", meaning: "wie, warum"}]},
  {"words": [{"kana": "どうぞ", meaning: "bitte sehr"}]},
  {"words": [{"kana": "とうふ", meaning: "Tofu"}]},
  {"words": [{"kana": "どうぶつ", meaning: "Tier"}]},
  {"words": [{"kana": "どうも", meaning: "sehr"}]},
  {"words": [{"kana": "とお", meaning: "10"}]},
  {"words": [{"kana": "とおい", meaning: "weit entfernt"}]},
  {"words": [{"kana": "とおか", meaning: "10. Tag eines Monats"}]},
  {"words": [{"kana": "とおかかん", meaning: "10 Tage"}]},
  {"words": [{"kana": "ときどき", meaning: "manchmal"}]},
  {"words": [{"kana": "とけい", meaning: "Uhr"}]},
  {"words": [{"kana": "どこ", meaning: "wo"}]},
  {"words": [{"kana": "どこか", meaning: "irgendwo"}]},
  {"words": [{"kana": "ところ", meaning: "Ort"}]},
  {"words": [{"kana": "とし", meaning: "Jahr"}]},
  {"words": [{"kana": "としょかん", meaning: "Bibliothek"}]},
  {"words": [{"kana": "どちら", meaning: "woher, welches"}]},
  {"words": [{"kana": "どっかい", meaning: "Leseverständnis"}]},
  {"words": [{"kana": "どっち", meaning: "woher, welches"}]},
  {"words": [{"kana": "とても", meaning: "sehr"}]},
  {"words": [{"kana": "どなた", meaning: "wer"}]},
  {"words": [{"kana": "となり", meaning: "Nachbarschaft"}]},
  {"words": [{"kana": "どの", meaning: "welches"}]},
  {"words": [{"kana": "とぶ", meaning: "springen"}]},
  {"words": [{"kana": "とまる", meaning: "halten"}]},
  {"words": [{"kana": "ともだち", meaning: "Freund"}]},
  {"words": [{"kana": "どようび", meaning: "Samstag"}]},
  {"words": [{"kana": "とり", meaning: "Vogel"}]},
  {"words": [{"kana": "とりにく", meaning: "Vogelfleisch"}]},
  {"words": [{"kana": "とる", meaning: "aufnehmen"}]},
  {"words": [{"kana": "とる", meaning: "nehmen"}]},
  {"words": [{"kana": "どれ", meaning: "welches"}]},
  {"words": [{"kana": "どんな", meaning: "welche Art"}]},
  {"words": [{"kana": "ない", meaning: "nicht existieren"}]},
  {"words": [{"kana": "なか", meaning: "innen"}]},
  {"words": [{"kana": "ながい", meaning: "lang"}]},
  {"words": [{"kana": "なかから", meaning: "aus"}]},
  {"words": [{"kana": "なく", meaning: "weinen"}]},
  {"words": [{"kana": "なくす", meaning: "etwas verlieren, verschwinden lassen"}]},
  {"words": [{"kana": "なぜ", meaning: "warum"}]},
  {"words": [{"kana": "なつ", meaning: "Sommer"}]},
  {"words": [{"kana": "なつやすみ", meaning: "Sommerferien"}]},
  {"words": [{"kana": "など", meaning: "usw."}]},
  {"words": [{"kana": "なな", meaning: "sieben"}]},
  {"words": [{"kana": "ななつ", meaning: "sieben Stück"}]},
  {"words": [{"kana": "ななひき", meaning: "7 Tiere"}]},
  {"words": [{"kana": "ななふん", meaning: "7 Minuten"}]},
  {"words": [{"kana": "ななほん", meaning: "7 (Flaschen, ..)"}]},
  {"words": [{"kana": "なに", meaning: "was, etwas"}]},
  {"words": [{"kana": "なのか", meaning: "7. Tag eines Monats"}]},
  {"words": [{"kana": "なのかかん", meaning: "7 Tage"}]},
  {"words": [{"kana": "なまえ", meaning: "Name"}]},
  {"words": [{"kana": "ならう", meaning: "lernen"}]},
  {"words": [{"kana": "ならぶ", meaning: "nebeneinander stehen"}]},
  {"words": [{"kana": "ならべる", meaning: "aufreihen"}]},
  {"words": [{"kana": "なる", meaning: "werden"}]},
  {"words": [{"kana": "なん", meaning: "was, etwas"}]},
  {"words": [{"kana": "なんかい", meaning: "wievielmal?"}]},
  {"words": [{"kana": "なんじかん", meaning: "wie viel Stunden"}]},
  {"words": [{"kana": "なんばん", meaning: "welche Nummer"}]},
  {"words": [{"kana": "に", meaning: "zwei"}]},
  {"words": [{"kana": "にがつ", meaning: "Februar"}]},
  {"words": [{"kana": "にぎやか", meaning: "lebhaft"}]},
  {"words": [{"kana": "にく", meaning: "Fleisch"}]},
  {"words": [{"kana": "にじ", meaning: "2 Uhr"}]},
  {"words": [{"kana": "にし", meaning: "Westen"}]},
  {"words": [{"kana": "にち", meaning: "Sonntag (Abkürzung)"}]},
  {"words": [{"kana": "にちようび", meaning: "Sonntag"}]},
  {"words": [{"kana": "にひき", meaning: "2 Tiere"}]},
  {"words": [{"kana": "にふん", meaning: "2 Minuten"}]},
  {"words": [{"kana": "にほん", meaning: "2 (Flaschen, ..)"}]},
  {"words": [{"kana": "にほん", meaning: "Japan"}]},
  {"words": [{"kana": "にほんご", meaning: "Japanisch"}]},
  {"words": [{"kana": "にもつ", meaning: "Gepäck"}]},
  {"words": [{"kana": "にわ", meaning: "Garten"}]},
  {"words": [{"kana": "ぬぐ", meaning: "ausziehen"}]},
  {"words": [{"kana": "ぬる", meaning: "malen, schmieren"}]},
  {"words": [{"kana": "ぬるい", meaning: "lauwarm"}]},
  {"words": [{"kana": "ねこ", meaning: "Katze"}]},
  {"words": [{"kana": "ねる", meaning: "schlafen"}]},
  {"words": [{"kana": "のぼる", meaning: "aufgehen"}]},
  {"words": [{"kana": "のみもの", meaning: "Getränk"}]},
  {"words": [{"kana": "のむ", meaning: "trinken"}]},
  {"words": [{"kana": "のる", meaning: "einsteigen, aufsteigen"}]},
  {"words": [{"kana": "は", meaning: "Zahn"}]},
  {"words": [{"kana": "はい", meaning: "ja"}]},
  {"words": [{"kana": "はいざら", meaning: "Aschenbecher"}]},
  {"words": [{"kana": "はいる", meaning: "hineingehen"}]},
  {"words": [{"kana": "はがき", meaning: "Postkarte"}]},
  {"words": [{"kana": "はく", meaning: "(Hose) anziehen"}]},
  {"words": [{"kana": "はこ", meaning: "Kasten"}]},
  {"words": [{"kana": "はし", meaning: "Stäbchen, Brücke"}]},
  {"words": [{"kana": "はじまる", meaning: "anfangen"}]},
  {"words": [{"kana": "はじめ", meaning: "Anfang"}]},
  {"words": [{"kana": "はじめて", meaning: "zum ersten Mal"}]},
  {"words": [{"kana": "はじめに", meaning: "zuerst"}]},
  {"words": [{"kana": "はじめまして", meaning: "Begrüßung bei erster Begegnung"}]},
  {"words": [{"kana": "はしる", meaning: "laufen"}]},
  {"words": [{"kana": "はたらく", meaning: "arbeiten"}]},
  {"words": [{"kana": "はち", meaning: "acht"}]},
  {"words": [{"kana": "はちがつ", meaning: "August"}]},
  {"words": [{"kana": "はちじ", meaning: "8 Uhr"}]},
  {"words": [{"kana": "はっぴき", meaning: "8 Tiere"}]},
  {"words": [{"kana": "はっぷん", meaning: "8 Minuten"}]},
  {"words": [{"kana": "はっぽん", meaning: "8 (Flaschen, ..)"}]},
  {"words": [{"kana": "はな", meaning: "Nase"}]},
  {"words": [{"kana": "はな", meaning: "Blume"}]},
  {"words": [{"kana": "はなし", meaning: "Geschichte, Gespräch"}]},
  {"words": [{"kana": "はなす", meaning: "sagen"}]},
  {"words": [{"kana": "はは", meaning: "(meine) Mutter"}]},
  {"words": [{"kana": "はやい", meaning: "früh, schnell"}]},
  {"words": [{"kana": "はる", meaning: "aufkleben, aufhängen, Frühling"}]},
  {"words": [{"kana": "はれ", meaning: "heiteres Wetter"}]},
  {"words": [{"kana": "はれる", meaning: "aufklaren"}]},
  {"words": [{"kana": "ばん", meaning: "Abend"}]},
  {"words": [{"kana": "ばんごう", meaning: "Nummer"}]},
  {"words": [{"kana": "ばんごはん", meaning: "Abendessen"}]},
  {"words": [{"kana": "はんぶん", meaning: "Hälfte"}]},
  {"words": [{"kana": "ぱんや", meaning: "Bäckerei"}]},
  {"words": [{"kana": "ひ", meaning: "Tag"}]},
  {"words": [{"kana": "ひがし", meaning: "Osten"}]},
  {"words": [{"kana": "ひく", meaning: "ziehen, (Instrument) spielen"}]},
  {"words": [{"kana": "ひくい", meaning: "niedrig"}]},
  {"words": [{"kana": "ひこうき", meaning: "Flugzeug"}]},
  {"words": [{"kana": "ひだり", meaning: "links"}]},
  {"words": [{"kana": "ひと", meaning: "Mensch"}]},
  {"words": [{"kana": "ひとつ", meaning: "ein"}]},
  {"words": [{"kana": "ひとり", meaning: "eine Person"}]},
  {"words": [{"kana": "ひま", meaning: "unbeschäftigt"}]},
  {"words": [{"kana": "ひゃく", meaning: "hundert"}]},
  {"words": [{"kana": "びょういん", meaning: "Krankenhaus"}]},
  {"words": [{"kana": "びょうき", meaning: "Krankheit"}]},
  {"words": [{"kana": "ひらがな", meaning: "Hiragana"}]},
  {"words": [{"kana": "ひる", meaning: "Mittag"}]},
  {"words": [{"kana": "ひるごはん", meaning: "Mittagessen"}]},
  {"words": [{"kana": "ひろい", meaning: "weit"}]},
  {"words": [{"kana": "ふうとう", meaning: "Umschlag"}]},
  {"words": [{"kana": "ふく", meaning: "Kleidung, blasen"}]},
  {"words": [{"kana": "ふたつ", meaning: "zwei"}]},
  {"words": [{"kana": "ぶたにく", meaning: "Schweinefleisch"}]},
  {"words": [{"kana": "ふたり", meaning: "zwei (Personen)"}]},
  {"words": [{"kana": "ふつか", meaning: "2. Tag eines Monats"}]},
  {"words": [{"kana": "ふつかかん", meaning: "2 Tage"}]},
  {"words": [{"kana": "ふとい", meaning: "dick"}]},
  {"words": [{"kana": "ふゆ", meaning: "Winter"}]},
  {"words": [{"kana": "ふる", meaning: "regnen"}]},
  {"words": [{"kana": "ふるい", meaning: "alt"}]},
  {"words": [{"kana": "ぶん", meaning: "Satz"}]},
  {"words": [{"kana": "ぶんしょう", meaning: "Aufsatz"}]},
  {"words": [{"kana": "ぶんぽう", meaning: "Grammatik"}]},
  {"words": [{"kana": "へた", meaning: "ungeschickt"}]},
  {"words": [{"kana": "へや", meaning: "Zimmer"}]},
  {"words": [{"kana": "へん", meaning: "Gegend"}]},
  {"words": [{"kana": "べんきょうする", meaning: "lernen, studieren"}]},
  {"words": [{"kana": "べんり", meaning: "Bequemlichkeit"}]},
  {"words": [{"kana": "ぼうし", meaning: "Hut"}]},
  {"words": [{"kana": "ほか", meaning: "anderes"}]},
  {"words": [{"kana": "ぼく", meaning: "ich"}]},
  {"words": [{"kana": "ほしい", meaning: "wünschen"}]},
  {"words": [{"kana": "ほそい", meaning: "dünn"}]},
  {"words": [{"kana": "ほん", meaning: "Buch"}]},
  {"words": [{"kana": "ほんだな", meaning: "Bücherregal"}]},
  {"words": [{"kana": "ほんとう", meaning: "wirklich"}]},
  {"words": [{"kana": "ほんや", meaning: "Bücherladen"}]},
  {"words": [{"kana": "まいあさ", meaning: "jeden Morgen"}]},
  {"words": [{"kana": "まいしゅう", meaning: "jede Woche"}]},
  {"words": [{"kana": "まいつき", meaning: "jeden Monat"}]},
  {"words": [{"kana": "まいとし", meaning: "jedes Jahr"}]},
  {"words": [{"kana": "まいにち", meaning: "jeden Tag"}]},
  {"words": [{"kana": "まいばん", meaning: "jeder Abend"}]},
  {"words": [{"kana": "まえ", meaning: "vor, vorn"}]},
  {"words": [{"kana": "まえに", meaning: "vor, früher"}]},
  {"words": [{"kana": "まがる", meaning: "sich biegen, abbiegen"}]},
  {"words": [{"kana": "まずい", meaning: "schlecht (schmecken)"}]},
  {"words": [{"kana": "まだ", meaning: "noch"}]},
  {"words": [{"kana": "また", meaning: "wieder"}]},
  {"words": [{"kana": "まち", meaning: "Ortschaft"}]},
  {"words": [{"kana": "まつ", meaning: "warten"}]},
  {"words": [{"kana": "まっすぐ", meaning: "gerade aus"}]},
  {"words": [{"kana": "まど", meaning: "Fenster"}]},
  {"words": [{"kana": "まるい", meaning: "rund"}]},
  {"words": [{"kana": "まん", meaning: "zehntausend"}]},
  {"words": [{"kana": "みがく", meaning: "polieren"}]},
  {"words": [{"kana": "みぎ", meaning: "rechts"}]},
  {"words": [{"kana": "みじかい", meaning: "kurz"}]},
  {"words": [{"kana": "みず", meaning: "Wasser"}]},
  {"words": [{"kana": "みせ", meaning: "Geschäft"}]},
  {"words": [{"kana": "みせる", meaning: "zeigen"}]},
  {"words": [{"kana": "みち", meaning: "Straße"}]},
  {"words": [{"kana": "みっか", meaning: "3. Tag eines Monats"}]},
  {"words": [{"kana": "みっかかん", meaning: "3 Tage"}]},
  {"words": [{"kana": "みっつ", meaning: "drei (Stück)"}]},
  {"words": [{"kana": "みどり", meaning: "Grün"}]},
  {"words": [{"kana": "みなさん", meaning: "meine Damen und Herren"}]},
  {"words": [{"kana": "みなみ", meaning: "Süden"}]},
  {"words": [{"kana": "みにいく", meaning: "besuchen"}]},
  {"words": [{"kana": "みみ", meaning: "Ohr"}]},
  {"words": [{"kana": "みる", meaning: "sehen, schauen"}]},
  {"words": [{"kana": "みんな", meaning: "alle Personen"}]},
  {"words": [{"kana": "むいか", meaning: "6. Tag eines Monats"}]},
  {"words": [{"kana": "むいかかん", meaning: "6 Tage"}]},
  {"words": [{"kana": "むこう", meaning: "entgegengesetzte Seite"}]},
  {"words": [{"kana": "むずかしい", meaning: "schwierig"}]},
  {"words": [{"kana": "むっつ", meaning: "sechs"}]},
  {"words": [{"kana": "むら", meaning: "Dorf"}]},
  {"words": [{"kana": "め", meaning: "Auge"}]},
  {"words": [{"kana": "めがね", meaning: "Brille"}]},
  {"words": [{"kana": "もう", meaning: "schon"}]},
  {"words": [{"kana": "もういちど", meaning: "noch einmal"}]},
  {"words": [{"kana": "もうすこし", meaning: "noch etwas"}]},
  {"words": [{"kana": "もく", meaning: "Donnerstag (Abkürzung)"}]},
  {"words": [{"kana": "もくようび", meaning: "Donnerstag"}]},
  {"words": [{"kana": "もじ", meaning: "Schriftzeichen"}]},
  {"words": [{"kana": "もつ", meaning: "haben"}]},
  {"words": [{"kana": "もってかえる", meaning: "mit nach Hause nehmen"}]},
  {"words": [{"kana": "もっと", meaning: "mehr"}]},
  {"words": [{"kana": "もの", meaning: "Ding"}]},
  {"words": [{"kana": "もらう", meaning: "bekommen"}]},
  {"words": [{"kana": "もん", meaning: "Tor"}]},
  {"words": [{"kana": "もんだい", meaning: "Frage"}]},
  {"words": [{"kana": "やさい", meaning: "Gemüse"}]},
  {"words": [{"kana": "やさしい", meaning: "leicht"}]},
  {"words": [{"kana": "やじるし", meaning: "Pfeil"}]},
  {"words": [{"kana": "やすい", meaning: "billig"}]},
  {"words": [{"kana": "やすみ", meaning: "Pause"}]},
  {"words": [{"kana": "やすむ", meaning: "ausruhen"}]},
  {"words": [{"kana": "やっつ", meaning: "acht"}]},
  {"words": [{"kana": "やま", meaning: "Berg"}]},
  {"words": [{"kana": "やる", meaning: "machen, geben"}]},
  {"words": [{"kana": "ゆうがた", meaning: "Abend"}]},
  {"words": [{"kana": "ゆうはん", meaning: "Abendessen"}]},
  {"words": [{"kana": "ゆうびんきょく", meaning: "Postamt"}]},
  {"words": [{"kana": "ゆうべ", meaning: "letzte Nacht"}]},
  {"words": [{"kana": "ゆうめい", meaning: "Berühmtheit"}]},
  {"words": [{"kana": "ゆき", meaning: "Schnee"}]},
  {"words": [{"kana": "ゆっくりと", meaning: "langsam"}]},
  {"words": [{"kana": "ようか", meaning: "8. Tag eines Monats"}]},
  {"words": [{"kana": "ようかかん", meaning: "8 Tage"}]},
  {"words": [{"kana": "ようふく", meaning: "(westliche) Kleidung"}]},
  {"words": [{"kana": "よく", meaning: "oft, gut, gründlich"}]},
  {"words": [{"kana": "よこ", meaning: "Seite"}]},
  {"words": [{"kana": "よじ", meaning: "4 Uhr"}]},
  {"words": [{"kana": "よっか", meaning: "4. Tag eines Monats"}]},
  {"words": [{"kana": "よっかかん", meaning: "4 Tage"}]},
  {"words": [{"kana": "よっつ", meaning: "4 (Anzahl)"}]},
  {"words": [{"kana": "よにん", meaning: "4 Personen"}]},
  {"words": [{"kana": "よぶ", meaning: "rufen"}]},
  {"words": [{"kana": "よむ", meaning: "lesen"}]},
  {"words": [{"kana": "よる", meaning: "Nacht"}]},
  {"words": [{"kana": "よわい", meaning: "schwach"}]},
  {"words": [{"kana": "よん", meaning: "vier"}]},
  {"words": [{"kana": "よんひき", meaning: "4 Tiere"}]},
  {"words": [{"kana": "よんぷん", meaning: "4 Minuten"}]},
  {"words": [{"kana": "よんほん", meaning: "4 (Flaschen, ..)"}]},
  {"words": [{"kana": "らいげつ", meaning: "nächsten Monat"}]},
  {"words": [{"kana": "らいしゅう", meaning: "nächste Woche"}]},
  {"words": [{"kana": "らいねん", meaning: "kommendes Jahr"}]},
  {"words": [{"kana": "りっぱ", meaning: "herrlich, schön"}]},
  {"words": [{"kana": "りゅうがくせい", meaning: "im Ausland Studierender"}]},
  {"words": [{"kana": "りょうしん", meaning: "Eltern"}]},
  {"words": [{"kana": "りょうり", meaning: "Kochen"}]},
  {"words": [{"kana": "りょこう", meaning: "Reise"}]},
  {"words": [{"kana": "りんご", meaning: "Apfel"}]},
  {"words": [{"kana": "れい", meaning: "Null"}]},
  {"words": [{"kana": "れいぞうこ", meaning: "Kühlschrank"}]},
  {"words": [{"kana": "れんしゅうする", meaning: "üben"}]},
  {"words": [{"kana": "ろうか", meaning: "Korridor"}]},
  {"words": [{"kana": "ろく", meaning: "sechs"}]},
  {"words": [{"kana": "ろくがつ", meaning: "Juni"}]},
  {"words": [{"kana": "ろくじ", meaning: "6 Uhr"}]},
  {"words": [{"kana": "ろっぴき", meaning: "6 Tiere"}]},
  {"words": [{"kana": "ろっぷん", meaning: "6 Minuten"}]},
  {"words": [{"kana": "ろっぽん", meaning: "6 (Flaschen, ..)"}]},
  {"words": [{"kana": "わかい", meaning: "jung"}]},
  {"words": [{"kana": "わかる", meaning: "verstehen"}]},
  {"words": [{"kana": "わすれる", meaning: "vergessen"}]},
  {"words": [{"kana": "わたし", meaning: "ich"}]},
  {"words": [{"kana": "わたす", meaning: "geben, überreichen"}]},
  {"words": [{"kana": "わたる", meaning: "sich erstrecken, überqueren"}]},
  {"words": [{"kana": "わるい", meaning: "schlecht"}]}
  ];
  
  export const sentencesKatakana = [
  {"words": [{"kana": "アクセサリー", meaning: "Schmuck, Zubehör"}]},
  {"words": [{"kana": "アジア", meaning: "Asien"}]},
  {"words": [{"kana": "アナウンサー", meaning: "(Nachrichten-) Sprecher"}]},
  {"words": [{"kana": "アパート", meaning: "Mietwohnung"}]},
  {"words": [{"kana": "アフリカ", meaning: "Afrika"}]},
  {"words": [{"kana": "アメリカ", meaning: "Amerika"}]},
  {"words": [{"kana": "アルコール", meaning: "Alkohol"}]},
  {"words": [{"kana": "アルバイト", meaning: "Nebenjob"}]},
  {"words": [{"kana": "エスカレーター", meaning: "Rolltreppe"}]},
  {"words": [{"kana": "エレベーター", meaning: "Fahrstuhl"}]},
  {"words": [{"kana": "オートバイ", meaning: "Motorrad"}]},
  {"words": [{"kana": "カーテン", meaning: "Gardinen"}]},
  {"words": [{"kana": "ガス", meaning: "Gas"}]},
  {"words": [{"kana": "ガソリン", meaning: "Benzin"}]},
  {"words": [{"kana": "ガソリンスタンド", meaning: "Tankstelle"}]},
  {"words": [{"kana": "カタカナ", meaning: "Katakana"}]},
  {"words": [{"kana": "カップ", meaning: "Tasse, Becher"}]},
  {"words": [{"kana": "カメラ", meaning: "Kamera"}]},
  {"words": [{"kana": "ガラス", meaning: "Glas (Material)"}]},
  {"words": [{"kana": "カレー", meaning: "Curry"}]},
  {"words": [{"kana": "カレンダー", meaning: "Kalender"}]},
  {"words": [{"kana": "ギター", meaning: "Gitarre"}]},
  {"words": [{"kana": "キレイ", meaning: "sauber, schön"}]},
  {"words": [{"kana": "キロ", meaning: "Kilogramm, Kilometer"}]},
  {"words": [{"kana": "キログラム", meaning: "Kilogramm"}]},
  {"words": [{"kana": "キロメートル", meaning: "Kilometer"}]},
  {"words": [{"kana": "クラス", meaning: "Klasse"}]},
  {"words": [{"kana": "ケーキ", meaning: "Kuchen"}]},
  {"words": [{"kana": "コート", meaning: "Mantel"}]},
  {"words": [{"kana": "コーヒー", meaning: "Kaffee"}]},
  {"words": [{"kana": "コップ", meaning: "Trinkglas"}]},
  {"words": [{"kana": "コトン", meaning: "Baumwolle"}]},
  {"words": [{"kana": "コンサート", meaning: "Konzert"}]},
  {"words": [{"kana": "コンピューター", meaning: "Computer"}]},
  {"words": [{"kana": "サッカー", meaning: "Fußball"}]},
  {"words": [{"kana": "サラダ", meaning: "Salat"}]},
  {"words": [{"kana": "サンダル", meaning: "Sandalen"}]},
  {"words": [{"kana": "サンドイッチ", meaning: "Sandwich"}]},
  {"words": [{"kana": "シャツ", meaning: "Hemd, Unterhemd"}]},
  {"words": [{"kana": "ジャム", meaning: "Marmelade"}]},
  {"words": [{"kana": "シャワー", meaning: "Dusche"}]},
  {"words": [{"kana": "ジュース", meaning: "Saft"}]},
  {"words": [{"kana": "スーツ", meaning: "Anzug"}]},
  {"words": [{"kana": "スーツケース", meaning: "Koffer"}]},
  {"words": [{"kana": "スカート", meaning: "Rock"}]},
  {"words": [{"kana": "スクリーン", meaning: "Bildschirm"}]},
  {"words": [{"kana": "ステーキ", meaning: "Steak"}]},
  {"words": [{"kana": "ステレオ", meaning: "Stereoanlage"}]},
  {"words": [{"kana": "ストーブ", meaning: "Ofen"}]},
  {"words": [{"kana": "スプーン", meaning: "Löffel"}]},
  {"words": [{"kana": "スポーツ", meaning: "Sport"}]},
  {"words": [{"kana": "ズボン", meaning: "Hose"}]},
  {"words": [{"kana": "スリッパ", meaning: "Pantoffeln"}]},
  {"words": [{"kana": "セーター", meaning: "Pullover"}]},
  {"words": [{"kana": "ゼロ", meaning: "Null"}]},
  {"words": [{"kana": "ソフト", meaning: "Software"}]},
  {"words": [{"kana": "タイプ", meaning: "Typ"}]},
  {"words": [{"kana": "タクシー", meaning: "Taxi"}]},
  {"words": [{"kana": "テープ", meaning: "Klebestreifen, Band"}]},
  {"words": [{"kana": "テーブル", meaning: "Tisch"}]},
  {"words": [{"kana": "テープレコーダー", meaning: "Kassettenrekorder"}]},
  {"words": [{"kana": "テキスト", meaning: "Text"}]},
  {"words": [{"kana": "テスト", meaning: "Test"}]},
  {"words": [{"kana": "テニス", meaning: "Tennis"}]},
  {"words": [{"kana": "デパート", meaning: "Kaufhaus"}]},
  {"words": [{"kana": "テレビ", meaning: "TV"}]},
  {"words": [{"kana": "ドア", meaning: "Tür"}]},
  {"words": [{"kana": "ドイツ", meaning: "Deutschland"}]},
  {"words": [{"kana": "トイレ", meaning: "Toilette"}]},
  {"words": [{"kana": "トイレットペーパー", meaning: "Toilettenpapier"}]},
  {"words": [{"kana": "ドレス", meaning: "Kleid"}]},
  {"words": [{"kana": "ナイフ", meaning: "Messer"}]},
  {"words": [{"kana": "ニュース", meaning: "Nachrichten"}]},
  {"words": [{"kana": "ネクタイ", meaning: "Krawatte"}]},
  {"words": [{"kana": "ノート", meaning: "Notiz, Notizbuch"}]},
  {"words": [{"kana": "パーティー", meaning: "Party"}]},
  {"words": [{"kana": "パート", meaning: "Teilzeitjob"}]},
  {"words": [{"kana": "バス", meaning: "Bus"}]},
  {"words": [{"kana": "パソコン", meaning: "Computer"}]},
  {"words": [{"kana": "バター", meaning: "Butter"}]},
  {"words": [{"kana": "パン", meaning: "Brot"}]},
  {"words": [{"kana": "ハンカチ", meaning: "Taschentuch"}]},
  {"words": [{"kana": "ハンドバッグ", meaning: "Handtasche"}]},
  {"words": [{"kana": "ピアノ", meaning: "Klavier"}]},
  {"words": [{"kana": "ビル", meaning: "Hochhaus, Gebäude"}]},
  {"words": [{"kana": "プール", meaning: "Pool"}]},
  {"words": [{"kana": "ファックス", meaning: "Fax"}]},
  {"words": [{"kana": "フィルム", meaning: "Fotofilm"}]},
  {"words": [{"kana": "プール", meaning: "Schwimmbecken"}]},
  {"words": [{"kana": "フォーク", meaning: "Gabel"}]},
  {"words": [{"kana": "プレゼント", meaning: "Geschenk"}]},
  {"words": [{"kana": "ページ", meaning: "Seite"}]},
  {"words": [{"kana": "ベッド", meaning: "Bett"}]},
  {"words": [{"kana": "ペット", meaning: "Haustier"}]},
  {"words": [{"kana": "ベル", meaning: "Klingel, Glocke"}]},
  {"words": [{"kana": "ペン", meaning: "Stift"}]},
  {"words": [{"kana": "ボールペン", meaning: "Kugelschreiber"}]},
  {"words": [{"kana": "ポケット", meaning: "(Hosen)tasche"}]},
  {"words": [{"kana": "ポスト", meaning: "Briefkasten"}]},
  {"words": [{"kana": "ボタン", meaning: "Knopf"}]},
  {"words": [{"kana": "ホテル", meaning: "Hotel (im westlichen Stil)"}]},
  {"words": [{"kana": "マーク", meaning: "Markierung"}]},
  {"words": [{"kana": "マッチ", meaning: "Streichholz"}]},
  {"words": [{"kana": "メートル", meaning: "Meter"}]},
  {"words": [{"kana": "メモ", meaning: "Notiz"}]},
  {"words": [{"kana": "ラーメン", meaning: "Ramen-Nudeln"}]},
  {"words": [{"kana": "ラジオ", meaning: "Radio"}]},
  {"words": [{"kana": "リポート", meaning: "Report"}]},
  {"words": [{"kana": "レコード", meaning: "Schallplatte"}]},
  {"words": [{"kana": "レジ", meaning: "Kasse"}]},
  {"words": [{"kana": "レストラン", meaning: "Restaurant"}]},
  {"words": [{"kana": "レポート", meaning: "Report"}]},
  {"words": [{"kana": "ワープロ", meaning: "Schreibprogramm"}]},
  {"words": [{"kana": "ワイシャツ", meaning: "Hemd"}]}
  ];
  