import React from 'react'

import styles from './Japanese.module.scss'

interface IJapaneseProps {
  text?: string
  children?: string
}

// sentence = [日本語:にほんご]を[勉強:べんきょう]する。
// result -> 日本語 and 勉強 have furigana
export function convertToRuby(sentence: string) {
  const q: any[] = [];
  let k = 0;
  sentence.match(/([^[\]_]+|\[[^[\]]+\]|_[^_]+_)/g).forEach((match: string) => {
    if (match.substr(0, 1) === '[') {
      const writingReading = match.substr(1, match.length - 2).split(':');
      q.push(<ruby key={k++}>{writingReading[0]}<rt>{writingReading[1]}</rt></ruby>);
    }
    else if (match.substr(0, 2) === '_s' ) {
      const small = match.substr(2, match.length - 3)
      q.push(<small key={k++}>{small}</small>)
    }
    else if (match.substr(0, 1) === '_') {
      let colored = ''
      let offset = 1
      if (match.substr(0, 2) === '_y' || match.substr(0, 2) === '_g' || match.substr(0, 2) === '_b' || match.substr(0, 2) === '_r' || match.substr(0, 2) === '_p') {
        colored = match.substr(0, 2)
        offset = 2
      }
      const mark = match.substr(offset, match.length - 1 - offset)
      q.push(<mark key={k++} className={`${styles.mark} ${styles[`markColor${colored}`]}`}>{mark}</mark>)
    }
    else if (match.length > 0) {
      q.push(<span key={k++}>{match}</span>);
    }
  });
  return (<>{q}</>)
}

export default (props: IJapaneseProps) => {
  if (props.text||props.children) {
    return convertToRuby(props.text||props.children);
  }
  return null;
}
