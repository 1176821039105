import React from 'react'
import MenuLink from './basic/MenuLink'

import styles from './Booklinks.module.scss'

interface IBooklinkProps {
  chapters: IBookChapter[]
  location: any
}

export interface IBookChapter {
  title: string
  pages: Array<{
    title: string
    path: string
  }>
}

const Booklinks = (props: IBooklinkProps) => {
  if (!props.chapters || props.chapters.length === 0) {
    return null;
  }

  return <>
    {props.chapters.map(e => (<div key={e.title} className={styles.chapter}>
      <h3 className={styles.chapterTitle}>{e.title}</h3>
      <ul className={styles.chapterLinks}>
        {e.pages.map(page => 
          (<li key={page.path}>
            {props.location.pathname === page.path && page.path && 
              <MenuLink className={styles.currentPage} to={page.path}>{page.title||page.path}</MenuLink>
            }
            {!page.path && (page.title||page.path) }
            {props.location.pathname !== page.path && page.path && 
              <MenuLink to={page.path}>{page.title||page.path}</MenuLink>
            }
          </li>))}
      </ul>
    </div>)
  )}</>;
};

export default Booklinks;