import React, { ReactNode } from 'react'
import styles from './TwoColumns.module.scss'

export const TwoColumns = (props: { children: ReactNode }) => {
  return <div className={styles.twoColumns}>{props.children}</div>;
}

export const LeftColumn = (props: { children: ReactNode }) => {
  return <div className={styles.leftColumn}>{props.children}</div>;
}

export const RightColumn = (props: { children: ReactNode }) => {
  return <div className={styles.rightColumn}>{props.children}</div>;
}

export const MiddleColumn = (props: { children: ReactNode}) => {
  return <div className={styles.middleColumn}>{props.children}</div>;
}

export const FinalColumn = (props: { children: ReactNode }) => {
  return <div className={styles.finalColumn}>{props.children}</div>;
}
