import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import * as u from '../../utils'

interface IWord {
  word: string
  kanji: string
  de: string
  kanjijlpt: string
  jlpt: string
}

interface IVocabularyTableProps {
  viewingJlpt: string
  list: string
}

export default (props: IVocabularyTableProps) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(250);

  const pageChanged = (event: any) => {
    setPage(parseInt(event.target.getAttribute('data-page'), 10));
  }

  const { allWordsYaml } = useStaticQuery(graphql`query VocabularyQuery {
    allWordsYaml {
      totalCount
      nodes {
        word
        kanji
        kanjijlpt
        de
        jlpt
      }
    }
  }`);
  const words = allWordsYaml.nodes.filter((e: IWord) => e.jlpt === props.list);

  const items = [];
  const pageCount = Math.ceil(words.length / rowsPerPage);
  for (let n = 0; n < pageCount; n++) {
    items.push(
      <Pagination.Item key={'page-'+n} active={n === page} onClick={pageChanged} data-page={n}>
        {n * rowsPerPage + 1} - {Math.min((n + 1) * rowsPerPage, words.length)}
      </Pagination.Item>,
    );
  }

  return (<div>
    <table>
      <thead>
        <tr><th>#</th><th>Kana (Kanji)</th><th>Bedeutung</th></tr>
      </thead>
      <tbody>
      { 
        words.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((node: IWord, index: number ) => {
            const kanjiCell = (u.jlptToNumber(node.kanjijlpt) < u.jlptToNumber(props.viewingJlpt) && node.kanji ? '＊' : '') + (node.kanji||'');
            const wordNum = page * rowsPerPage + index + 1;
            return (<tr key={'word-' + wordNum}>
              <td>{wordNum}</td>
              <td>{node.word}{kanjiCell?`（${kanjiCell}）`:''}</td>
              <td>{node.de}</td>
            </tr>);
        })
      }
      </tbody>
    </table>
    <Pagination>{items}</Pagination>
  </div>);
}