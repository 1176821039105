export function matchAll(str: string, regexp: RegExp) {
  const matches: any[] = [];
  let match = regexp.exec(str);
  while (match !== null) {
    matches.push(match);
    match = regexp.exec(str);
  };
  return matches;
}

export function jlptToNumber(str: string) {
  if (!str || str.length !== 2) {
    return 0;
  }

  return parseInt(str.charAt(1), 10);
}

export function slugify(str: string) {
  return str.toLowerCase().replace(/ /g, '-');
}