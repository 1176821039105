import React, { ReactNode } from 'react'
import styles from './ActionButton.module.scss'

interface IActionButtonProps {
  children: ReactNode
  to: string
  action: string
}

export default (props: IActionButtonProps) => {
  return <div className={styles.actionButton}>
    <div>{props.children}</div>
    <a href={props.to}>{props.action}</a>
  </div>
}
