import React, { ReactNode } from 'react'
import styles from './Emoji.module.scss'

import heart from '../../Tipping/heart.png'

const emojis = {
  heart
}

export default (props: { emoji: 'heart' }) => {
  const extraStyle = styles[`emoji_${props.emoji}`]?styles[`emoji_${props.emoji}`]:''
  return <img className={styles.emoji + ' ' + extraStyle} src={emojis[props.emoji]}/>
}
