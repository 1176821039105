import React, { ReactNode } from 'react'

import Japanese from './Japanese'

import styles from './Example.module.scss'

const Example = (props: { children: ReactNode }) => {
  return <table className={styles.example}>
    <tbody>
      {props.children}
    </tbody>
  </table>
}

const De = (props: { children: ReactNode }) => {
  if (typeof props.children === 'string') {
    let key = 0
    const italic = props.children.indexOf('|') === -1
    return <tr className={italic?styles.exampleItalic:styles.exampleDe}>
    { props.children.split('|').map(e => (
      <td key={key++}><Japanese text={e} /></td>
    )) }
  </tr>
  }
  else {
    return <tr>{props.children}</tr>
  }
}

const Jp = (props: { children: ReactNode }) => {
  if (typeof props.children === 'string') {
    let key = 0
    return <tr>
    { props.children.split('|').map(e => (
      <td key={key++}><Japanese text={e} /></td>
    )) }
  </tr>
  }
  else {
    return <tr>{props.children}</tr>
  }
}

export { De, Example, Jp }
