import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import MenuLink from '../components/basic/MenuLink'
import BlogCommon from '../components/blogcommon'
import ActionButton from '../components/embeddable/ActionButton'
import Centered from '../components/embeddable/Centered'
import Emoji from '../components/embeddable/Emoji'
import { De, Example, Jp } from '../components/embeddable/Example'
import GrammarTable from '../components/embeddable/GrammarTable'
import IconList, { IconItem } from '../components/embeddable/IconList'
import Japanese from '../components/embeddable/Japanese'
import KanaTrainer from '../components/embeddable/KanaTrainer'
import { FinalColumn, LeftColumn, MiddleColumn, RightColumn, TwoColumns } from '../components/embeddable/TwoColumns'
import VocabularyTable from '../components/embeddable/VocabularyTable'
import Helmet from '../components/Helmet'
import * as u from '../utils'

// const postProcessMdx = (text: string) => {
//   console.log(text)
//   return text.replace(/"/g, '„')
// }
//  “

const BlogTemplate = (props: { data: any, location: any, pageContext: any}) => {
  const { frontmatter, body } = props.data.mdx;
  const headings = props.data.mdx.headings.map((e: any) => ({
    title: e.value,
    url: '#' + u.slugify(e.value)
  }));

  return (
    <BlogCommon booklinks={frontmatter.template!=='single'?props.data.booksJson:undefined} 
                title={frontmatter.title}
                date={frontmatter.date}
                captions={frontmatter.template!=='single'?headings:undefined}
                location={props.location}>
      <Helmet 
        path={props.location.pathname}
        frontmatter={frontmatter}
        isArticle={true}
        book={{...props.data.booksJson, titleInBook: props.pageContext.titleInBook}} />
      <MDXProvider components={{
        ActionButton,
        Centered,
        De,
        Emoji,
        Example,
        FinalColumn,
        GrammarTable,
        IconItem,
        IconList,
        Japanese,
        Jp,
        KanaTrainer,
        LeftColumn,
        MiddleColumn,
        RightColumn,
        TwoColumns,
        VocabularyTable,
        a: ap => ap.href?<MenuLink to={ap.href}>{ap.children}</MenuLink>:<a {...ap}/>,
        h2: p => <h2 {...p} id={u.slugify(p.children)}/>
      }}>
        <MDXRenderer data={props.data}>
          {body}
        </MDXRenderer>
      </MDXProvider>
    </BlogCommon>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query($absolutePath: String!, $book: String) {
    mdx(fileAbsolutePath: { eq: $absolutePath }) {
      body
      frontmatter {
        date(formatString: "DD. MMMM, YYYY", locale: "de")
        title
        template
      }
      headings(depth: h2) {
        value
      }
    }
    booksJson(bookid: {eq: $book}) {
      title
      path
      chapters {
        pages {
          path
          title
        }
        title
      }
    }
  }
`